<template>
  <div :class="`index ${$utils.isMobile().mobile ? 'mobile' : 'pc'}`">
    <div class="content">
      <div class="goods-detail">
        <img :src="$ossRes('/activity/feilong_goods.png')" alt="" srcset="" />
        <p class="title">“飞龙”骨雕艺术汽车珍藏模型</p>
        <p class="price"><i>¥</i>6888.00</p>
      </div>
      <div class="goods-intro">
        <p>商品详情</p>
        <img :src="$ossRes('/activity/feilong_details.png')" alt="" srcset="" />
        <!-- <span @click="clear">clear</span> -->
      </div>
    </div>
    <div class="btns">
      <div
        style="position: relative; left: 0; top: 0"
        class="img"
        v-if="$utils.checkWxOrMini(1)"
      >
        <WxOpenLaunchWeapp :path="`subpackage_login_cy/cs/cs`">
          <img
            style="width: 100%; height: 100%"
            :src="$ossRes('/activity/feilong_kefu.png')"
            alt=""
            srcset=""
          />
        </WxOpenLaunchWeapp>
      </div>
      <div
        class="others-item cursor-pointer"
        @click.stop.prevent="csOpen"
        v-if="!$utils.checkWxOrMini(1)"
      >
        <img :src="$ossRes('/activity/feilong_kefu.png')" alt="" srcset="" />
      </div>

      <div
        v-if="!userInfo && $utils.checkWxOrMini(0)"
        class="login cursor-pointer"
        @click.stop.prevent="popLoginOpen"
      >
        <div class="create-action cursor-pointer">
          <span>立即购买</span>
        </div>
      </div>
      <div
        v-else-if="!userInfo && $utils.checkWxOrMini(1) && !weappPathFinished"
        class="buy-wx-open"
      >
        <WxOpenLaunchWeapp :path="weappPath">
          <div
            style="
              width: 272px;
              height: 40px;
              line-height: 40px;
              background: #4d12da;
              border-radius: 205px 205px 205px 205px;
              text-align: center;
              position: relative;
              z-index: 999;
            "
          >
            <span
              style="
                font-size: 18px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
              "
              >立即购买</span
            >
          </div>
        </WxOpenLaunchWeapp>
      </div>
      <div
        v-else
        class="create-action cursor-pointer"
        @click.stop.prevent="payShow = true"
      >
        <span>立即购买</span>
      </div>
    </div>
    <pop-login
      :show="popLoginShow"
      :projectcode="project_code"
      @close="popLoginClose"
      @loginsuccess="loginSuccess"
    ></pop-login>
    <action-confirm :show="csShow" @close="csClose" :needAction="false">
      <div class="action-confirm-tips">
        <img :src="csQrcode.url" />
        <span>长按识别二维码联系客服</span>
      </div>
    </action-confirm>
    <action-confirm :show="pcPayShow" @close="pcPayClose" :needAction="false">
      <iframe :src="iframe_url" frameborder="0"></iframe>
      <p class="iframe-p">请使用微信扫一扫进行付款</p>
    </action-confirm>
    <action-confirm
      style="height: auto"
      :show="payShow"
      @close="payClose"
      :needAction="false"
    >
      <div class="action-confirm-pay">
        <p>收货信息</p>
        <div class="items">
          <span><i>*</i>姓名</span>
          <input
            class="item-input"
            v-model="user_name"
            placeholder="请输入姓名"
          />
        </div>
        <div class="items">
          <span><i>*</i>手机</span>
          <input
            class="item-input"
            v-model="phone"
            placeholder="请输入手机号码"
          />
        </div>
        <div class="items">
          <span><i>*</i>收货地址</span>
          <input
            class="item-input"
            v-model="address"
            placeholder="请输入收货地址"
          />
        </div>
        <div
          class="create-action cursor-pointer pay-action"
          @click.stop.prevent="payFunc"
        >
          <span>去支付</span>
        </div>
      </div>
    </action-confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert, routesData } from "@/router/index";
import { wxShare } from "@/assets/js/wechatAuth.js";
import ActionConfirm from "@/components/common/ActionConfirm.vue";
import PopLogin from "@/components/PopLogin.vue";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";
export default {
  components: { ActionConfirm, PopLogin, WxOpenLaunchWeapp },
  data() {
    return {
      csShow: false,
      payShow: false,
      user_name: "",
      phone: "",
      address: "",
      timer: null,
      weappPathFinished: false,
      project_code: "zPqDQxtCgBROSEaJ",
      popLoginShow: false,
      weappStyle: {
        div: {
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          position: "absolute",
          left: "0",
          top: "0",
        },
        span: {
          fontSize: "14.4px",
          fontFamily: "Source Han Sans CN-Light, Source Han Sans CN",
          fontWeight: "300",
          color: "#ffffff",
        },
      },
      qrcode_scene: "",
      iframe_url: "",
      pcPayShow: false,
      num: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["csQrcode"]),
    weappPath() {
      return `subpackage_login_cy/index/index?scene=${
        process.env.NODE_ENV == "development" ? "1_" : "_"
      }1_${this.qrcode_scene}_1${
        sessionStorage.getItem("share_id")
          ? "_" + sessionStorage.getItem("share_id")
          : ""
      }${this.$storage.getLogoutExist() ? "&dc_skip=1" : ""}`;
    },
  },
  created() {
    wxShare({});
    console.log("this.userInfo", this.userInfo);
    // if(this.$storage.getToken()){
    //   // 未登录
    // // }else{
    // if (!this.userInfo) {
    //   // 未登录
    //   // this.$router.replace({
    //   //   name: routesData.index.name,
    //   // });
    // } else {
    this.qrcode_scene = Date.now();
    this.$store.dispatch("saveCsQrcode", {
      notActivityId: true,
    });
    let _this = this;
    if (!this.userInfo) {
      setTimeout(() => {
        _this.internalRequest();
      }, 1500);
    } else this.checkOrder();
    // }
    // }
  },
  destroyed() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    pcPayClose() {
      this.pcPayShow = false;
      this.iframe_url = "";
    },
    clear() {
      localStorage.clear();
      sessionStorage.clear();
    },
    internalRequest() {
      // 场景：微信h5内首页有唤醒小程序扫码入口，需持续轮询检测登录。
      if (this.userInfo) {
        // 已登录
        return;
      }
      if (!this.$utils.checkWxOrMini(1)) {
        // 非微信h5端内
        return;
      }
      if (this.weappLoginFinished) {
        // 已扫码登录完成
        return;
      }
      const _this = this;
      _this.$http
        .post("/user/wxAuthNew", {
          timestamp: _this.qrcode_scene,
        })
        .then((res) => {
          if (res.code == 200 && res.data && res.data.user_info) {
            _this.$storage.setToken(res.data.user_info.token);
            _this.$store.dispatch("saveUserInfo", res.data.user_info);
            _this.$store.dispatch("saveConfig", res.data.config);
            _this.$toast.success("登录成功");
            _this.weappLoginFinished = true;
            _this.qrcode_scene = Date.now();
          } else {
            setTimeout(() => {
              _this.internalRequest();
            }, 1500);
          }
        });
    },
    loginSuccess() {
      // this.$store.dispatch("saveCsQrcode", {
      //   notActivityId: true,
      // });
      this.checkOrder();
      console.log("loginSuccess");
    },
    popLoginClose() {
      this.popLoginShow = false;
    },
    popLoginOpen() {
      if (sessionStorage.getItem("isWxMini") == 1) {
        parent.wx.miniProgram.reLaunch({
          url: `/subpackage_login_cy/game/game?id=1${
            process.env.NODE_ENV == "development" ? "_1" : "_"
          }&al=1${this.$storage.getLogoutExist() ? "&dc_skip=1" : ""}`,
        });
      } else {
        this.popLoginShow = true;
      }
    },
    payFunc() {
      if (!this.user_name) {
        this.$toast.fail("请输入姓名");
        return;
      }
      if (!this.phone) {
        this.$toast.fail("请输入手机号码");
        return;
      }
      if (!this.address) {
        this.$toast.fail("请输入收货地址");
        return;
      }
      let requestParam = {
        money: 688800,
        delivery_address: {
          user_name: this.user_name,
          phone: this.phone,
          address: this.address,
        },
      };
      this.iframe_url = "";
      this.$http.post("/order/model-order", requestParam).then((res) => {
        if (res.code == 200) {
          if (!this.$utils.isMobile().wechat) {
            let url = `https://show.333f.com/payment/?desc=${encodeURIComponent(
              "飞龙骨雕艺术汽车珍藏模型"
            )}&money=${
              requestParam.money
            }&account=2&mode=native&project_code=zPqDQxtCgBROSEaJ&out_trade_no=${
              res.data.order_no
            }`;
            this.iframe_url = url;
            this.payShow = false;
            this.pcPayShow = true;
            this.checkOrder(20);
          } else
            window.location.href = `https://show.333f.com/payment/?desc=${encodeURIComponent(
              "飞龙骨雕艺术汽车珍藏模型"
            )}&money=${
              requestParam.money
            }&account=2&project_code=zPqDQxtCgBROSEaJ&out_trade_no=${
              res.data.order_no
            }&openid=${this.userInfo.openid}`;
        }
      });
    },
    checkOrder(num) {
      console.log("aaa");
      let that = this;
      clearTimeout(this.timer);
      this.timer = null;
      this.$http.get("/order/has-buy-model").then((res) => {
        if (res.code == 200) {
          if (res.data.has_buy) {
            this.$router.push({
              name: routesConvert(routesModuleData.activityFeilongSuccess, true)
                .name,
            });
          } else {
            if (num) {
              if (that.num > num) {
                clearTimeout(that.timer);
                that.timer = null;
              } else
                that.timer = setTimeout(() => {
                  that.num++;
                  that.checkOrder(num);
                }, 2000);
            } else {
              if (that.num > 3) {
                clearTimeout(that.timer);
                that.timer = null;
              } else
                that.timer = setTimeout(() => {
                  that.num++;
                  that.checkOrder();
                }, 2000);
            }
          }
        }
      });
    },
    payClose() {
      this.payShow = false;
    },
    csOpen() {
      if (sessionStorage.getItem("isWxMini")) {
        parent.wx.miniProgram.redirectTo({
          url: `/subpackage_login_cy/cs/cs`,
        });
      } else {
        this.csShow = true;
      }
    },
    csClose() {
      this.csShow = false;
    },
    // back() {
    //   this.$router.back();
    // },
  },
};
</script>

<style lang="scss">
.index {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  // overflow: hidden;
  background: #fafafc;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  // background: url(#{$PublicEnv}/activity_my/my_activity_bg_pc.png);
  // background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .content {
    // max-width: 2160px;
    margin: 0 auto;
    position: relative;

    .goods-detail {
      background: #fff;
      margin-bottom: 123px;

      img {
        width: 100%;
      }

      .title {
        font-size: 184px;
        font-family: Source Han Sans-Medium, Source Han Sans;
        font-weight: 500;
        color: #333333;
        line-height: 205px;
        padding: 133px 154px;
      }

      .price {
        font-size: 287px;
        font-family: DIN-Bold, DIN;
        font-weight: 700;
        color: #ba2f36;
        line-height: 256px;
        padding: 0 0 195px 154px;

        i {
          font-size: 174px;
          font-family: Source Han Sans-Medium, Source Han Sans;
          font-weight: 500;
          color: #ba2f36;
          line-height: 154px;
          font-style: normal;
          padding-right: 31px;
        }
      }
    }

    .goods-intro {
      padding-bottom: 787px;
      background: #fff;

      p {
        font-size: 174px;
        -webkit-text-size-adjust: 100% !important;
        text-size-adjust: 100% !important;
        -moz-text-size-adjust: 100% !important;
        background: url(#{$PublicEnv}/activity/feilong_title.png);
        background-size: 1761px 26px;
        background-repeat: no-repeat;
        text-align: center;
        background-position: center;
        line-height: 462px;
      }

      img {
        width: 100%;
      }
    }
  }

  .btns {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // height: 737px;
    padding: 82px 0 220px;
    background: #fff;
    width: 101%;
    // height: 661px;

    img,
    .img {
      width: 461px;
      height: 461px;
      border-radius: 100%;
      box-shadow: 0 0 200px #9d9d9d;
    }
  }
}
.iframe-p {
  font-size: 174px;
  padding-top: 300px;
}
.others-item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin-right: 136px;
  span {
    font-size: 40px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 350;
    color: #ffffff;
  }
  // img {
  //   width: 26px;
  //   height: 26px;
  //   margin-left: 12px;
  //   transform: rotate(180deg);
  // }
  &.product {
    transition: all 3s ease-in-out;
    &.active {
      img {
        transform: rotate(0);
      }
    }
    .product-nav {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -125px;
      width: 250px;
      height: 248px;
      border-radius: 22px;
      background: rgba(44, 54, 96, 1);
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 48px 0;
      &.active {
        visibility: visible;
        top: 100px;
        opacity: 1;
      }
      span {
        font-size: 30px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        width: 100%;
        height: 64px;
        display: block;
        box-sizing: border-box;
        padding: 0 54px;
        line-height: 64px;
        transition: all 0.3s ease-in-out;
        &:first-child {
          background: linear-gradient(
            90deg,
            rgba(68, 86, 176, 1),
            rgba(36, 60, 115, 0) 60%
          );
        }
      }
    }
  }
}
.buy-wx-open {
  width: 2780px;
  height: 410px;
  position: relative;
}
.create-action {
  // width: 2780px;
  // height: 410px;
  // background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
  // background-size: 100% 100%;
  // display: flex;
  // justify-content: center;
  // padding-top: 14px;
  // box-sizing: border-box;
  width: 2780px;
  height: 410px;
  line-height: 410px;
  background: linear-gradient(82deg, #5300c7 4%, #452cf7 45%, #21affd 99%);
  border-radius: 205px 205px 205px 205px;
  text-align: center;
  position: relative;
  z-index: 999;

  span {
    font-size: 184px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
}

.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 360px;
    height: 360px;
    margin-top: 160px;
    margin-bottom: 46px;
  }

  span {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #646464;

    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}

.action-confirm-pay {
  width: 100%;
  // height: 100%;
  display: flex;
  //   justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 205px;
    font-family: Source Han Sans-Medium, Source Han Sans;
    font-weight: 600;
    color: #333333;
    padding-bottom: 200px;
  }

  .items {
    display: flex;
    align-items: center;
    height: 507px;
    position: relative;

    span {
      width: 730px;
      display: block;
      letter-spacing: 2px;
      font-size: 143px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #666666;

      i {
        font-size: 154px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 400;
        color: #aa232a;
        padding-right: 28px;
      }
    }

    .item-input {
      width: 1462px;
      background: transparent;
      border: none;
      padding: 0 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 143px;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      color: #333333;

      &::placeholder {
        font-size: 143px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #bbbbbb;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #333333;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: scaleX(0.9) scaleY(0.1);
      transform-origin: center;
      margin: auto;
    }
  }

  .pay-action {
    margin-top: 625px;
  }
}

@media (orientation: portrait) {
  .index {
    display: block;
    padding-bottom: 0;
  }

  .action-confirm-tips {
    img {
      width: 1840px;
      height: 1840px;
      margin-top: 257px;
      margin-bottom: 155px;
    }

    span {
      font-size: 184px;

      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}

@media (orientation: landscape) {
  .pc {
    .content {
      transform: scale(0.4);
      transform-origin: top;
    }
    .action-confirm-pay {
      transform: scale(0.3);
      transform-origin: top;
      margin-top: 300px;
    }
    .btns {
      transform: scale(0.4);
      transform-origin: bottom;
    }
    iframe {
      margin-top: 300px;
    }
    .iframe-p {
      font-size: 60px;
      padding-top: 100px;
    }
  }
}
</style>
